import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'board-main';

const getMemberOptions = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/members`, {
		headers: await authHeader(),
		params: query,
	});
};

const list = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}`, {
		headers: await authHeader(),
		params: query,
	});
};

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const updateInformation = async (payload) => {
	return axios.patch(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const updateDescription = async (payload) => {
	return axios.patch(`${API_URL_DEFAULT}${APP_MODEL}/description`, payload, {
		headers: await authHeader(),
	});
};

const close = async (payload) => {
	return axios.patch(`${API_URL_DEFAULT}${APP_MODEL}/close`, payload, {
		headers: await authHeader(),
	});
};

const softDelete = async (payload) => {
	return axios.delete(`${API_URL_DEFAULT}${APP_MODEL}`, {
		data: payload,
		headers: await authHeader(),
	});
};

const checkBoard = async (query) => {
	return axios.get(`${API_URL_DEFAULT}boards/check-board`, {
		params: query,
		headers: await authHeader(),
	});
};

const getCoreUser = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/core-user`, {
		params: query,
		headers: await authHeader(),
	});
};

export default {
	getMemberOptions,
	list,
	create,
	updateInformation,
	updateDescription,
	close,
	softDelete,
	checkBoard,
	getCoreUser,
};
